// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '@morioh/v-lightbox/dist/lightbox.css';
@import '~vuejs-dialog/dist/vuejs-dialog.min.css';

.container{
    max-width: 1380px;
}
.img-fluid{
    width: 100%;
}
.scrollbox{
    max-height: 400px;
    overflow-y: scroll;
}
.navbar{
    .nav-link{
        font-size: 16px;
        font-weight: 500;
    }
}
h1{
    font-size: 2rem;
}
h2{
    font-size: 1.5rem;
}
h3{
    font-size: 1.2rem;
}

@media(max-width: 992px){
    .navbar-brand{
        width: auto;
        height: auto;
        margin: 0;
    }
    nav .btn-light, nav .btn-light:hover, nav .btn-outline-light, nav .btn-outline-light:hover{
        background: $primary!important;
        border: none!important;
        padding: 0.5rem 0!important;
        margin: 0!important;
        color: #fff!important;
        font-size: 16px!important;
        font-weight: 500!important;
        box-shadow: none!important;
    }
    nav .dropdown-menu.show{
        background: $primary;
        border: 0!important;
        padding-top: 0!important;
    }
    nav .dropdown-item{
        color: #fff!important;
        border-bottom: solid 1px #58709e;
        padding: 0.5rem 0!important;
    }
    nav .dropdown-item:last-child{
        border-bottom: none!important;
    }
}
#home-intro{
    background-color: $primary;
    // background-image: url('/img/bg-768.png');
    background-size: cover;
    background-position: center;
    min-height: 55vh;

    .content-form{
        padding-top: 5%;
    }
}
@media(min-width: 768px) and (max-width: 1400px){
    #home-intro{
        background-image: url('/img/bg-1200.webp');
        .content-form{
            padding-top: 20%;
        }
    }
}
@media(min-width: 1400px){
    #home-intro{
        background-image: url('/img/bg-2000.webp');
        .content-form{
            padding-top: 20%;
        }
    }
}
.btn-light {
    background-color: #fbfbfb;
    border-color: #fbfbfb;
}
.mh-200{
    min-height: 200px;
}
.fs-12{
    font-size: 12px;
}
.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-18{
    font-size: 18px;
}
.fs-20{
    font-size: 20px;
}
.fs-22{
    font-size: 22px;
}
.fs-24{
    font-size: 24px;
}
.mh-150{
    max-height: 150px;
    width: auto;
}
.main-logo{
    width: 190px;
    height: auto;
}
@media(max-width: 768px){
    .main-logo{
        width: 115px;
    }
    .nav-item{
        border-bottom: solid 1px #fff;
    }
    .navbar-expand-md{
        background: $primary;
    }
}
.homepage-card{
    .actions{
        position: absolute;
        bottom: 0;
        left: 12px;
        right: 12px;
    }
}
#footer{
    padding: 25px 0 15px 0;
    // background: #ececec;
    background: $primary;
    color: #fff;
}
.btn-primary, .btn-outline-primary:hover{
    color: #fff;
}
main{
    min-height: calc(100vh - 240px);
}
.city-banner:hover{
    cursor: pointer;
    opacity: 0.8;
}
.listing-item{
    h3 {
        font-size: 22px;
    }
    min-height: 200px;
}
.listing-item:hover, .homepage-card:hover{
    box-shadow: 0 0.5rem 0.7rem rgb(0 0 0 / 15%) !important;
    transition: box-shadow 0.4s;
}
.listing-item, .homepage-card{
    transition: box-shadow 0.2s;
}
.listing-stars{
    position: absolute;
    right: 0;
    font-size: 17px;
}
#filter_form{
    width: 100%;
}
.dg-main-content{
    border-radius: 0;
}
.dg-content{
    text-align: center;
    font-size: 20px;
}
.dg-btn--ok{
    background: $primary;
    border: solid 1px $primary;
    color: #fff;
    border-radius: 0;
}
.dg-btn--cancel{
    background: #fff;
    color: $primary;
    border: solid 1px  $primary;
    border-radius: 0;
}
#vmvt_block{
    display: none;
}
#sidebar_filter input[type="radio"]:checked + label{
    font-weight: 600;
    color: $primary;
}
#sidebar_filter input[type="radio"] + label:hover{
    color: $primary;
    // text-decoration: underline;
    cursor: pointer;
}
@media(max-width: 768px){
    .feature-text{
        display: none;
    }
    .listing-logo{
        text-align: center;
    }
    .listing-logo img{
        max-width: 65%;
    }
    .page-item.middle{
        display: none;
    }
}
#comment_form input[type="radio"] + label{
    font-size: 22px;
    color: $primary;
}
#comment_form input[type="radio"]:checked ~ label{
    color: #333;
}
#gdpr{
    display: none;
    z-index: 99999999999;
    width: 100%;
    padding: 35px 25px 25px 25px;
    background: $primary;
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    .btn{
        float: right;
    }
}
.breed-list{
    -moz-column-count: 4;
    -moz-column-gap: 1em;
    -webkit-column-count: 4;
    -webkit-column-gap: 1em;
    column-count: 4;
    column-gap: 1em;
}
.premium-badge{
    position: absolute;
    z-index: 1;
    background: #465e8d;
    color: #fff;
    font-size: 12px;
    padding: 2px 5px;
}
#single_listing .premium-badge{
    font-size: 15px;
    right: 0;
}
